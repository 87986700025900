import * as React from 'react'

import { FC } from 'src/utils/types'

export const SvgJank: FC<{ height?: number }> = ({ height }) => {
	const { svgRef, runimationRef } = useRunimation()

	return (
		<svg
			ref={svgRef}
			//
			height={height}
			//
			viewBox="0 0 100 100"
			className="debug"
			//
			onClick={() => {
				runimationRef.current.disconnect()
			}}
		>
			<line x1={50} y1={0} x2={50} y2={100} stroke="white" />
			<g transform={`translate(${50 - 10 - 10 / 2} 20)`}>
				{/* <g> */}
				<rect width="10" height="16" rx="5" fill="hotpink" />
				<ellipse rx="4" ry="3" cx="5" cy="9" fill="white" />
				<ellipse rx="1" ry="1" cx="5" cy="9" fill="black" />
			</g>
			<g transform={`translate(${50 + 10 - 10 / 2} 20)`}>
				<rect width="10" height="16" rx="5" fill="hotpink" />
				<ellipse rx="4" ry="3" cx="5" cy="9" fill="white" />
				<ellipse rx="1" ry="1" cx="5" cy="9" fill="black" />
			</g>
			<Eye
				transform="rotate(90 50 50)"
				x={50 - 10 - 16 / 2}
				y={40}
				width={16}
				height={16}
				//
				opacity={0.25}
			/>
			<Eye
				transform="rotate(-90 50 50)"
				x={50 + 10 - 16 / 2}
				y={40}
				width={16}
				height={16}
				//
				opacity={0.25}
			/>
			<Eye
				x={50 - 16 / 2}
				y={60}
				width={16}
				height={16}
				//
				opacity={0.25}
			/>
		</svg>
	)
}

const Eye = (props: React.SVGProps<SVGSVGElement>) => (
	<svg viewBox="0 0 100 100" {...props} transform="">
		{/* <rect width="100%" height="100%" fill="grey" /> */}

		<g transform={props.transform}>
			{/* <rect width="100%" height="100%" fill="beige" rx="15" /> */}

			<rect width="60" height="100" rx="30" x="20" fill="hotpink" />
			<ellipse rx="24" ry="18" cx="50" cy="55" fill="white" />
			<ellipse rx="8" ry="8" cx="50" cy="55" fill="black" />
		</g>
	</svg>
)

// [
// 	React.MutableRefObject<SVGSVGElement | null>,
// 	React.MutableRefObject<Runimation>,
// ]
const useRunimation = () => {
	const svgRef = React.useRef<SVGSVGElement>(null)
	const runimationRef = React.useRef(new Runimation())

	React.useEffect(() => {
		if (svgRef.current) {
			runimationRef.current.connect(svgRef.current)
		} else {
			runimationRef.current.disconnect()
		}
	}, [svgRef.current])

	return { svgRef, runimationRef }
}

class Runimation {
	_svg?: SVGSVGElement

	// constructor() {}
	get svg() {
		if (!this._svg) {
			throw new Error('No SVG')
		}
		return this._svg
	}

	connect(el: SVGSVGElement) {
		this._svg = el

		// this.svg.style.background = 'orange'
	}

	disconnect() {
		// this.svg.style.background = 'none'
	}

	update() {}
}
