import './Autosize.css'

import * as React from 'react'

import { MeasureStack } from 'src/app/ui/measurements'
import { FC } from 'src/utils/types'

export const Autosize = () => {
	return (
		<div className="Autosize">
			<h2>Fixed area</h2>
			<div className="Autosize-section">
				<MeasureStack rowChildren={<RC />} colChildren={<CC />} />
			</div>
			<h2>Resizable area</h2>
			<div className="Autosize-section Autosize-resize">
				<MeasureStack rowChildren={<RC />} colChildren={<CC />} />
			</div>
		</div>
	)
}

const Item: FC = ({ children }) => (
	<div
		style={{
			padding: 10,
		}}
		children={children}
	/>
)

const RC = () => (
	<>
		<Item>12345</Item>
		<Item>1234567890</Item>
		<Item>12345678901234567890</Item>
	</>
)

const CC = () => (
	<>
		<Item>I am</Item>
		<Item>in columns</Item>
		<Item>now</Item>
	</>
)
