import './Connery.css'

import * as React from 'react'

import * as Shawn00 from './shawn_00'
import * as Shawn02 from './shawn_02'
import * as Shawn06 from './shawn_06'
import * as Shawn08 from './shawn_08'

const SHAWNS = {
	Shawn00,
	Shawn02,
	Shawn06,
	Shawn08,
}

export const Connery = () => {
	const [phrase, setPhrase] = React.useState('She sells sea shells.')
	const [pedantic, setPedantic] = React.useState(false)

	const [shawned, setShawned] = React.useState<Record<string, string>>({})

	React.useEffect(() => {
		const newShawned: Record<string, string> = {}

		Object.entries(SHAWNS).forEach(([key, shawn]) => {
			let result = ''
			try {
				result = shawn.rephrase(phrase, pedantic)
			} catch {
				result = '<Error>'
			}
			newShawned[key] = result
			setShawned(newShawned)
		})
	}, [phrase, pedantic])

	return (
		<div className="Connery">
			<h2>Telefactor: Connery</h2>
			<p>
				This is a demo of several "Shawn" implementations written for a
				<a href="https://github.com/telefactor/telefactor">Telefactor</a> game
				themed around Sean Connery. Players needed to implement a function that
				translates a string into its Sean pronunciation. However, they were not
				given this task explicitly, but instead given unit tests that were
				written by other players.
			</p>
			<p>
				Use the text below to see how the implementations became...{' '}
				<em>interesting</em> as the code was passed between programmers. Also,
				see if you can figure out what "pedantic" mode does.
			</p>
			<div className="Connery-inputs">
				<input
					type="text"
					className="Connery-inputText"
					placeholder="Some sentence"
					value={phrase}
					onChange={e => setPhrase(e.target.value)}
				/>
				<label>
					<input
						type="checkbox"
						checked={pedantic}
						onChange={e => setPedantic(e.target.checked)}
					/>
					Pedantic?
				</label>
			</div>

			<div>
				<h3>Round 00</h3>
				<blockquote>{shawned['Shawn00'] || '...'}</blockquote>

				<p>
					<a href="https://github.com/ssangervasi/shawn-00/tree/master/src/shawn.ts">
						Author: ssangervasi
					</a>
				</p>
				<p>
					This is the reference implementation that kicked off the game of
					telephone.
				</p>
			</div>

			<div>
				<h3>Round 02</h3>
				<blockquote>{shawned['Shawn02'] || '...'}</blockquote>

				<p>
					<a href="https://github.com/ssangervasi/shawn-02/tree/master/src/shawn.ts">
						Author: NVanmeter
					</a>
				</p>
				<p>
					This is the first implementation that was written based entirely off
					of tests, with no access to the original implemenation.
				</p>
			</div>

			<div>
				<h3>Round 04</h3>
				<blockquote>...</blockquote>

				<p>
					<a href="https://github.com/ssangervasi/shawn-04/tree/master/src/shawn.ts">
						Author: clanam
					</a>
				</p>
				<p>This implementation does not run on this page, for two reasons:</p>
				<ol>
					<li>
						This was the only implementation that was split across multiple
						files, so resolving imports is more of a chore.
					</li>
					<li>
						This implementation includes a "sanity" meter which, among other
						humorous alterations, means the output is hard to compare with other
						implemenations.
					</li>
				</ol>
			</div>

			<div>
				<h3>Round 06</h3>
				<blockquote>{shawned['Shawn06'] || '...'}</blockquote>

				<p>
					<a href="https://github.com/ssangervasi/shawn-06/tree/master/src/shawn.ts">
						Author: Brian
					</a>
				</p>
				<p>
					Some amount of sanity has been restored, though trying to convert the
					same phrase multiple times is interesting...
				</p>
			</div>

			<div>
				<h3>Round 08</h3>
				<blockquote>{shawned['Shawn08'] || '...'}</blockquote>

				<p>
					<a href="https://github.com/ssangervasi/shawn-08/tree/master/src/shawn.ts">
						Author: ninduni
					</a>
				</p>
				<p>
					And, last but not least, this implementation seems very consistent
					with its predecessor. It fixes an error case for "pedantic" mode as
					well!
				</p>
			</div>
		</div>
	)
}
