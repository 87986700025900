import * as React from 'react'
import { Route, Switch } from 'react-router-dom'

import { MdPage } from 'src/app/mdPage/MdPage'
import { PageList } from 'src/app/ui/PageList'

import { links } from './links'

export const Thoughts = () => {
	return (
		<div className="Thoughts">
			<Switch>
				{links.map(
					({ link, page }) =>
						page && (
							<Route key={link} path={link}>
								<MdPage path={page} />
							</Route>
						),
				)}

				<Route>
					<h1>Programming Thoughts</h1>
					<p>Writing about writing code</p>

					<PageList links={links} />
				</Route>
			</Switch>
		</div>
	)
}
