import * as React from 'react'

import { fmtJSON } from 'src/utils/fmt'
import { FC } from 'src/utils/types'

interface Pos {
	x: number
	y: number
}

const posEq = (a: Pos, b: Pos) => a.x === b.x && a.y === b.y

type Keyframe = number
type Keyframes = Keyframe[]
type OnKeyframe = (event: {
	keyframe: Keyframe
	element: HTMLElement
	scroll: Pos
}) => void

/**
 * stick - Maintain exact scrolling position.
 * drift: P - Scroll until the element hits the top of the page, then stick there for P pixels.
 * range: [S, E] - Only be visible between Start and End pixels.
 */
type ControlledOpts =
	| {
			stick: true
			drift?: never
			range?: never
			keyframes?: never
			onKeyframe?: never
	  }
	| {
			stick?: never
			drift: number
			range?: never
			keyframes?: never
			onKeyframe?: never
	  }
	| {
			stick?: never
			drift?: never
			range: [number, number]
			keyframes?: never
			onKeyframe?: never
	  }
	| {
			stick?: never
			drift?: never
			range?: never
			keyframes: Keyframes
			onKeyframe: OnKeyframe
	  }

type Mode = 'stick' | 'drift' | 'range' | 'keyframes' | 'none'

type ControlledData = {
	mode: Mode
	drift?: number
	range?: [number, number]
	keyframes?: Keyframes
	onKeyframe?: OnKeyframe
	initial: Pos
}

export class ScrollControl {
	private previous = {
		x: 0,
		y: 0,
	}
	private requested?: Pos

	private elementToData: Map<HTMLElement, ControlledData> = new Map()

	get pos(): Pos {
		return this.requested ?? this.previous
	}

	// constructor() {}

	connect(element: HTMLElement, opts: ControlledOpts) {
		const box = element.getBoundingClientRect()
		const mode = opts.stick
			? 'stick'
			: typeof opts.drift === 'number'
			? 'drift'
			: Array.isArray(opts.range)
			? 'range'
			: Array.isArray(opts.keyframes)
			? 'keyframes'
			: 'none'
		this.elementToData.set(element, {
			mode,
			drift: opts.drift,
			range: opts.range,
			keyframes: opts.keyframes,
			onKeyframe: opts.onKeyframe,
			initial: {
				x: box.x,
				y: box.y,
			},
		})

		if (this.elementToData.size !== 1) {
			return
		}

		this.previous = {
			x: window.scrollX,
			y: window.scrollY,
		}

		window.addEventListener('scroll', this.handleScroll)
	}

	disconnect(el: HTMLElement) {
		this.elementToData.delete(el)

		if (this.elementToData.size !== 0) {
			return
		}

		window.removeEventListener('scroll', this.handleScroll)
	}

	// update() {}

	private handleScroll = (ev: Event) => {
		const isFirstRequest = this.requested === undefined
		this.requested = {
			x: window.scrollX,
			y: window.scrollY,
		}

		if (!isFirstRequest) {
			return
		}

		requestAnimationFrame(() => {
			this.control()

			this.requested = undefined
			this.previous = {
				x: window.scrollX,
				y: window.scrollY,
			}
		})
	}

	private control() {
		for (const [el, data] of this.elementToData) {
			if (data.mode === 'stick') {
				this.controlStick(el)
			} else if (data.mode === 'drift') {
				this.controlDrift(el, data)
			} else if (data.mode === 'range') {
				this.controlRange(el, data)
			} else if (data.mode === 'keyframes') {
				this.controlKeyframes(el, data)
			}
		}
	}

	private controlStick(el: HTMLElement) {
		el.style.transform = `translate3d(0, ${this.pos.y}px, 0)`
	}

	private controlDrift(el: HTMLElement, data: ControlledData) {
		const drift = data.drift as number
		const startY = data.initial.y
		const endY = data.initial.y + drift
		const targetY = Math.max(startY, Math.min(this.pos.y, endY))
		const translateY = Math.max(0, targetY - startY)

		el.style.transform = `translate3d(0, ${translateY}px, 0)`
		el.setAttribute('data-drift-calc', `${startY}, ${endY}`)
	}

	private controlRange(el: HTMLElement, data: ControlledData) {
		const [rangeStart, rangeEnd] = data.range as [number, number]
		const y = this.pos.y
		if (rangeStart <= y && y <= rangeEnd) {
			el.style.opacity = '1'
		} else {
			el.style.opacity = '0'
		}
	}

	private controlKeyframes(el: HTMLElement, data: ControlledData) {
		const keyframes = data.keyframes as Keyframes
		const onKeyframe = data.onKeyframe as OnKeyframe
		const y = this.pos.y
		const prevY = this.previous.y

		let prevKf: Keyframe | undefined
		let newKf: Keyframe | undefined
		for (const kf of keyframes) {
			if (kf < prevY) {
				prevKf = kf
			}

			if (kf < y) {
				newKf = kf
			}
		}

		if (prevKf === undefined || newKf === undefined) {
			return
		}
		// console.log('controlKeyframes', {
		// 	keyframes,
		// 	y,
		// 	prevY,
		// 	prevKf,
		// 	newKf,
		// })

		// Only trigger when the keyframe switches
		if (prevKf !== newKf) {
			onKeyframe({
				keyframe: newKf,
				element: el,
				scroll: this.pos,
			})
		}
	}
}

const insertDebug = (el: HTMLElement, data: unknown) => {
	let debugEl: HTMLPreElement | null = el.querySelector('#scroll-control-debug')
	if (!debugEl) {
		debugEl = document.createElement('pre')
		debugEl.id = 'scroll-control-debug'
		debugEl.style.position = 'absolute'
		debugEl.style.top = '0'
		debugEl.style.height = '100%'
		debugEl.style.width = '100%'
		debugEl.style.color = 'limegreen'
		debugEl.style.backgroundColor = 'rgba(50, 50, 80, 0.70)'

		el.appendChild(debugEl)
	}

	const text = fmtJSON(data)
	debugEl.innerText = text
}

const ScrollControlContext = React.createContext(new ScrollControl())

export const ScrollControlArea: FC = ({ children }) => {
	const divRef = React.useRef<HTMLDivElement>(null!)
	const scrollControlRef = React.useRef(new ScrollControl())

	return (
		<ScrollControlContext.Provider value={scrollControlRef.current}>
			<div
				ref={divRef}
				style={{
					position: 'relative',
					transform: 'translate3d(0, 0, 0)',
				}}
			>
				{children}
			</div>
		</ScrollControlContext.Provider>
	)
}

export const ScrollControlled: FC<
	ControlledOpts & {
		className?: string
		style?: Omit<React.CSSProperties, 'position' | 'transform'>
	}
> = ({ children, style, className, ...opts }) => {
	const divRef = React.useRef<HTMLDivElement>(null!)
	const sc = React.useContext(ScrollControlContext)

	React.useEffect(() => {
		sc.connect(divRef.current, opts)

		return () => {
			sc.disconnect(divRef.current)
		}
	}, [])

	return (
		<div
			ref={divRef}
			className={className}
			style={{
				position: 'relative',
				transform: 'translate3d(0, 0, 0)',
				...style,
			}}
		>
			{children}
		</div>
	)
}
