import './ExampleDataChart.css'

import * as React from 'react'

import { Json } from 'src/app/ui/Json'
import EXAMPLE_DATA from 'src/data/example.json'

export const ExampleDataChart = () => {
	return (
		<div className="">
			<h2>Some imported data</h2>
			<p>
				This data was imported through a bundled JSON file instead of an API
				request.
			</p>
			<Json data={EXAMPLE_DATA} />
		</div>
	)
}
