import * as d3 from 'd3'

import { binIndexToFreq, DB_MAX, DB_MIN, FREQ_MAX } from './audio'

export class Visualizer {
	domainX = [0, FREQ_MAX]
	domainY = [DB_MIN, DB_MAX]

	axisSpace = 100
	width = 600 - this.axisSpace
	height = 400 - this.axisSpace

	svg?: d3.Selection<SVGSVGElement, number, null, unknown>

	get x() {
		return d3.scaleLinear(this.domainX, [0, this.width])
	}
	get y() {
		return d3.scaleLinear(this.domainY, [0, -this.height])
	}
	get area() {
		return d3
			.area<number>()
			.x((datum, i) => this.x(binIndexToFreq(i)))
			.y1((datum, i) => this.y(datum))
	}

	connect(el: SVGSVGElement) {
		this.svg = d3.select(el)

		const parentEl = el.parentElement
		if (parentEl) {
			this.resize({
				width: parentEl.clientWidth,
			})

			const resizeObserver = new ResizeObserver(() => {
				this.resize({
					width: parentEl.clientWidth,
				})
			})

			resizeObserver.observe(parentEl)
		}

		this.svg.append('path').attr('class', 'data').attr('stroke', '#D00000')

		this.drawAxes()
		this.resize()
	}

	draw(dataArray: Float32Array) {
		if (!this.svg) {
			return
		}

		this.svg
			.select('path.data')
			.data(() => [dataArray])
			.join('path')
			.attr('d', this.area)
			.attr('fill', '#D0000050')
	}

	private resize(
		opts: {
			width?: number
			height?: number
			axisSpace?: number
		} = {},
	) {
		this.axisSpace = opts.axisSpace || this.axisSpace

		if (opts.width) {
			this.width = opts.width - this.axisSpace
		}
		if (opts.height) {
			this.height = opts.height - this.axisSpace
		}

		if (!this.svg) {
			return
		}

		this.drawAxes()

		this.svg
			.attr('width', this.width + this.axisSpace)
			.attr('height', this.height + this.axisSpace)

		const graphTranslation = `translate(
			${(2 / 3) * this.axisSpace},
			${this.height + (1 / 3) * this.axisSpace}
		)`

		this.svg.select('g.axes').attr('transform', graphTranslation)
		this.svg.select('path.data').attr('transform', graphTranslation)

		this.svg
			.select('text.label-x')
			.attr(
				'transform',
				`translate(${(1 / 2) * this.width}, ${(2 / 3) * this.axisSpace})`,
			)

		this.svg
			.select('text.label-y')
			.attr(
				'transform',
				`translate(${(-2 / 3) * this.axisSpace}, ${(-1 / 2) * this.height})`,
			)
	}

	private drawAxes() {
		if (!this.svg) {
			return
		}

		const existingAxes = this.svg.select('g.axes')
		if (existingAxes.node()) {
			existingAxes.remove()
		}

		const axes = this.svg
			.append('g')
			.attr('class', 'axes')
			.attr('stroke', 'grey')

		const axisX = d3.axisBottom(this.x).ticks(10, 's')
		axes.append('g').attr('class', 'axis-x').call(axisX)

		const axisY = d3.axisLeft(this.y).ticks(10, 's')
		axes.append('g').attr('class', 'axis-y').call(axisY)

		axes.append('text').attr('class', 'label-x').text('Hz').attr('fill', 'grey')
		axes.append('text').attr('class', 'label-y').text('dB').attr('fill', 'grey')
	}
}
