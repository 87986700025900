declare global {
	namespace Sangervasi {
		let isDebugEnabled: boolean
	}
}
global.Sangervasi = { isDebugEnabled: false }

const isDebugEnabled = () =>
	process.env.NODE_ENV !== 'production' || global.Sangervasi.isDebugEnabled

const prodNoOp = <Args, Result>(f: (...args: Args[]) => Result) => (
	...args: Args[]
): Result | void => (isDebugEnabled() ? f(...args) : undefined)

export const log = prodNoOp(console.log)
export const lerr = prodNoOp(console.error)
export const l = log
