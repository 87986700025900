import './MdPage.css'

import * as React from 'react'

import { FC } from 'src/utils/types'

export const MdPage: FC<{ path: string }> = ({ path }) => {
	const contentRef = React.useRef<HTMLDivElement>(null!)

	React.useEffect(() => {
		fillContent(contentRef, path)
	}, [path])

	return <div ref={contentRef} className="MdPage"></div>
}

const fillContent = async (
	contentRef: React.MutableRefObject<HTMLDivElement>,
	path: string,
) => {
	const res = await fetch(`/pages/${path}`)
	const text = await res.text()
	contentRef.current.innerHTML = text
}

/**
 * A fun way to get XSS into my markdown pages!
 */
// const runScripts = async (element: HTMLDivElement) => {
// 	const scripts = element.getElementsByTagName('script')
// 	Array.from(scripts).forEach(scriptEl => {
// 		const parent = scriptEl.parentElement
// 		if (!parent) {
// 			return
// 		}

// 		parent.removeChild(scriptEl)

// 		const clonedNode = document.createElement('script')
// 		if (scriptEl.src) {
// 			clonedNode.src = scriptEl.src
// 			clonedNode.type = scriptEl.type
// 			clonedNode.async = scriptEl.async
// 		} else {
// 			clonedNode.textContent = scriptEl.textContent
// 		}

// 		parent.appendChild(clonedNode)
// 	})
// }
