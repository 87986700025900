import './Pop.css'

import * as React from 'react'

import { FC } from 'src/utils/types'

type S = 'is-hidden' | 'is-visible'
export interface PopContextValue {
	state: S
	toggle: (state?: S | boolean) => void
}
export const PopContext = React.createContext<PopContextValue>({
	state: 'is-hidden',
	toggle: () => {},
})

const _Pop: FC<{
	button: React.ReactNode
	children: React.ReactNode
	contentClass?: string
}> = props => {
	const containerRef = React.useRef<HTMLDivElement>(null)

	const [state, toggle] = React.useReducer(
		(prev: S, state: S | boolean | undefined) => {
			if (typeof state === 'boolean') {
				return state ? 'is-visible' : 'is-hidden'
			}
			if (typeof state === 'string') {
				return state
			}
			return prev === 'is-hidden' ? 'is-visible' : 'is-hidden'
		},
		'is-hidden',
	)

	const valueRef = React.useRef<PopContextValue>({
		state,
		toggle,
	})
	if (valueRef.current.state !== state) {
		valueRef.current = {
			state,
			toggle,
		}
	}

	React.useEffect(() => {
		const cb = (event: MouseEvent) => {
			const container = containerRef.current
			const target = event.target
			if (!(container && target)) {
				return
			}
			if (container.contains(target as HTMLElement)) {
				return
			}
			toggle(false)
		}
		document.addEventListener('click', cb)
		return () => {
			document.removeEventListener('click', cb)
		}
	}, [])

	return (
		<div className="Pop" ref={containerRef}>
			<PopContext.Provider value={valueRef.current}>
				{props.button}
				<div className={`Pop-content ${state} ${props.contentClass || ''}`}>
					{props.children}
				</div>
			</PopContext.Provider>
		</div>
	)
}

export const Pop = Object.assign(_Pop, { Context: PopContext })
