import { log } from 'src/utils/logging'
import { timeout, TimeoutError } from 'src/utils/promises'
import { ms } from 'src/utils/time'

import { DB_MAX, DB_MIN } from './audio'

export class MicConnector {
	error?: Error

	connection?: {
		context: AudioContext
		stream: MediaStream
		track: MediaStreamTrack
		// source: MediaStreamTrackAudioSourceNode
		source: MediaStreamAudioSourceNode
		analyser: AnalyserNode
	}

	dataArray?: Float32Array
	minDecibels = DB_MIN
	maxDecibels = DB_MAX

	timeoutSec = 10
	intervalId = 0
	intervalMs = 100
	listener?: (dataArray: Float32Array) => void

	listen = (
		listener: (dataArray: Float32Array) => void,
		intervalMs?: number,
	) => {
		this.listener = listener

		if (intervalMs && intervalMs > 0) {
			this.intervalMs = intervalMs
		}
	}

	connect = async () => {
		log('Connecting')
		if (this.connection) {
			this.disconnect()
		}

		let stream: MediaStream | undefined
		try {
			stream = await timeout(
				navigator.mediaDevices.getUserMedia({
					audio: true,
				}),
				ms(this.timeoutSec),
			)
		} catch (err) {
			if (err instanceof Error) {
				this.error = err
			}

			if (err instanceof TimeoutError) {
				throw new Error(
					`Unable to connect to microphone within ${this.timeoutSec} seconds.`,
				)
			} else {
				throw new Error('Microphone permission refused.')
			}
		}
		log('Received media stream', stream)

		const context = new AudioContext()
		const track = stream.getAudioTracks()[0]
		const source = context.createMediaStreamSource(stream)
		const analyser = context.createAnalyser()
		source.connect(analyser)

		this.intervalId = window.setInterval(this.update, this.intervalMs)
		this.connection = {
			context,
			stream,
			track,
			source,
			analyser,
		}

		log('Connected', this.connection)
	}

	update = () => {
		if (!this.connection) {
			return
		}

		const bufferLength = this.connection.analyser.frequencyBinCount
		const dataArray = new Float32Array(bufferLength)
		this.dataArray = dataArray
		this.connection.analyser.getFloatFrequencyData(dataArray)
		this.clip(dataArray)

		const listener = this.listener
		if (listener) {
			setTimeout(() => {
				listener(dataArray)
			}, 0)
		}
	}

	disconnect = () => {
		log('Disconnecting', this.connection)
		if (this.connection) {
			this.connection.track.stop()
			this.connection.context.close()
		}
		this.connection = undefined
		this.error = undefined
		window.clearInterval(this.intervalId)
	}

	clip(dataArray: Float32Array): void {
		for (let i = 0; i < dataArray.length; i += 1) {
			if (dataArray[i] < this.minDecibels) {
				dataArray[i] = this.minDecibels
			} else if (this.maxDecibels < dataArray[i]) {
				dataArray[i] = this.maxDecibels
			}
		}
	}
}
