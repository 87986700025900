import { PageLink } from 'src/app/ui/PageList'

export const links: PageLink[] = [
	{
		date: '2021-12-18',
		link: '/playground/paralaxative',
		name: 'Paralaxative',
	},
	{
		date: '2021-12-04',
		link: '/playground/autosize',
		name: 'Autosize',
	},
	{
		date: '2021-01-31',
		link: '/playground/wave-mic',
		name: 'Microphone Waveform',
	},
	{
		date: '2018',
		link: '/playground/connery',
		name: 'Connery',
	},
]
