import { PageLink } from 'src/app/ui/PageList'

export const links: PageLink[] = [
	{
		date: '2021-10-02',
		link: '/projects/steamed-hams',
		name: 'Video To Terminal Unicode In Rust (Steamed Hams)',
		page: 'projects/steamed-hams',
	},
	{
		date: '2021-06-29',
		link: '/projects/tiasbitb',
		name: 'There is a Spiky Ball in the Bathtub',
		page: 'projects/tiasbitb',
	},
	// {
	// 	date: 'TBD',
	// 	link: '/projects/wave-mic/part-3',
	// 	name: 'Microphone Waveform: Part 3',
	// 	page: 'projects/wave-mic-part-3',
	// },
	{
		date: '2021-06-22',
		link: '/projects/wave-mic/part-2',
		name: 'Microphone Waveform: Part 2',
		page: 'projects/wave-mic-part-2',
	},
	{
		date: '2021-01-31',
		link: '/projects/wave-mic',
		name: 'Microphone Waveform: Part 1',
		page: 'projects/wave-mic',
	},
	{
		date: '2021-01-20',
		link: '/projects/this-site',
		name: 'This site',
		page: 'projects/this-site',
	},
	{
		date: '2020-03-20',
		link: '/projects/telefactor',
		name: 'Telefactor - The game of refactoring',
		page: 'projects/telefactor',
	},
	{
		date: '2017-06-17',
		link: '/projects/textploration',
		name: 'Textploration',
		page: 'projects/textploration',
	},
]
