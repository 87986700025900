import * as d3 from 'd3'
import * as React from 'react'

export const Declarative = () => {
	return (
		<div className="">
			<h2>Declarative/Imperative Smell</h2>

			<div style={{ background: '#BBB' }}>
				<Circles />
			</div>
		</div>
	)
}

// const Circles = () => {
// 	const svgRef = React.useRef<SVGSVGElement>(null)

// 	React.useEffect(() => {
// 		const svg = d3.select(svgRef.current)

// 		// Draw ten red circles
// 		for (let i = 1; i <= 10; i++) {
// 			svg
// 				.append('circle')
// 				.attr('stroke', '#D00000')
// 				.attr('cx', i * 20)
// 				.attr('cy', 10)
// 				.attr('r', 10)
// 		}
// 	})

// 	return <svg ref={svgRef} />
// }

// const Circles = () => {
// 	const [clicks, setClicks] = React.useState(0)

// 	const svgRef = React.useRef<SVGSVGElement>(null)

// 	React.useEffect(() => {
// 		const svg = d3.select(svgRef.current)
// 		// Draw a red circle
// 		for (let i = 1; i <= clicks; i++) {
// 			svg
// 				.append('circle')
// 				.attr('stroke', '#D00000')
// 				.attr('cx', i * 20)
// 				.attr('cy', 10)
// 				.attr('r', 10)
// 		}
// 	}, [clicks])

// 	return <svg ref={svgRef} onClick={() => setClicks(clicks + 1)} />
// }

const Circles = () => {
	const [clicks, setClicks] = React.useState(0)

	const svgRef = React.useRef<SVGSVGElement>(null)
	const circlerRef = React.useRef(
		new Circler({
			color: '#D00000',
			radius: 10,
		}),
	)

	React.useEffect(() => {
		const circler = circlerRef.current
		circler.connect(svgRef.current!)

		return () => {
			circler.disconnect()
		}
	}, [])

	React.useEffect(() => {
		circlerRef.current.draw(clicks)
	}, [clicks])

	return <svg ref={svgRef} onClick={() => setClicks(clicks + 1)} />
}

class Circler {
	private svg?: d3.Selection<SVGSVGElement, unknown, null, unknown>

	/**
	 * The constructor is for configuration options, *not* for items that
	 * can change between lifecycle steps.
	 */
	constructor(public options: { color: string; radius: number }) {}

	/**
	 * The
	 */
	connect(el: SVGSVGElement) {
		this.svg = d3.select(el)
	}

	disconnect() {
		this.clear()
		this.svg = undefined
	}

	draw(n: number) {
		if (!this.svg) {
			return
		}

		// Remove any existing circles.
		this.clear()

		// Draw all circles from scratch.
		for (let i = 1; i <= n; i++) {
			this.svg
				.append('circle')
				.attr('stroke', this.options.color)
				.attr('cx', i * this.options.radius * 2)
				.attr('cy', this.options.radius)
				.attr('r', this.options.radius)
		}
	}

	private clear() {
		if (!this.svg) {
			return
		}

		this.svg.selectAll('circle').remove()
	}
}
