import * as React from 'react'
import { Link } from 'react-router-dom'

import { FC } from 'src/utils/types'

export interface PageLink {
	date?: string
	link?: string
	href?: string
	name: string
	page?: string
}

export const PageList: FC<{ links: PageLink[] }> = ({ links }) => {
	return (
		<ul>
			{links.map(({ link, href, name, date }) => (
				<li key={link}>
					{date && (
						<>
							<span className="u-tabularNums">{date}</span> -{' '}
						</>
					)}
					{link ? <Link to={link}>{name}</Link> : null}
					{href ? <a href={href}>{name}</a> : null}
				</li>
			))}
		</ul>
	)
}
