import './Nav.css'

import * as React from 'react'
import { NavLink } from 'react-router-dom'

import { MeasureStack } from 'src/app/ui/measurements'
import { FC } from 'src/utils/types'

export const Nav = () => {
	return (
		<nav className="Nav">
			<MeasureStack rowChildren={<Items />} colChildren={<ColDrawer />} />
		</nav>
	)
}

const ColDrawer = () => {
	const containerRef = React.useRef<HTMLDivElement>(null)

	type S = 'is-collapsed' | 'is-expanded'
	const [state, toggle] = React.useReducer((prev: S, action?: S) => {
		if (action) {
			return prev === 'is-collapsed' ? 'is-expanded' : 'is-collapsed'
		}
		return 'is-collapsed'
	}, 'is-collapsed')

	React.useEffect(() => {
		const cb = (event: MouseEvent) => {
			const container = containerRef.current
			const target = event.target
			if (!(container && target)) {
				return
			}
			if (container.contains(target as HTMLElement)) {
				return
			}
			toggle()
		}
		document.addEventListener('click', cb)
		return () => {
			document.removeEventListener('click', cb)
		}
	}, [])

	return (
		<div ref={containerRef}>
			<button className="Nav-hamburger" onClick={toggle}>
				🍔
			</button>
			<div className={`Nav-drawer ${state}`}>
				<Items />
			</div>
		</div>
	)
}

const Item: FC<React.ComponentProps<typeof NavLink>> = props => {
	return (
		<div className="Nav-item">
			<NavLink activeClassName="is-active" {...props} />
		</div>
	)
}

const Items = () => (
	<>
		<Item to="/me">Sebastian</Item>
		<Item to="/dictle">Dictle</Item>
		<Item to="/dev">Dev Blog</Item>
	</>
)
