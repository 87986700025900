export const timer = async (ms: number) => {
	return new Promise(resolve => {
		setTimeout(() => {
			resolve(null)
		}, ms)
	})
}

export const later = <R>(fn: () => R): Promise<R> => {
	return new Promise(resolve => {
		setTimeout(() => {
			resolve(fn())
		}, 0)
	})
}

export const timeout = async <Payload>(
	promise: Promise<Payload>,
	ms: number,
): Promise<Payload> => {
	let result:
		| { wasCaptured: true; payload: Payload }
		| { wasCaptured: false } = { wasCaptured: false }

	const capture = promise.then(payload => {
		result = {
			wasCaptured: true,
			payload,
		}
	})

	return await Promise.race([capture, timer(ms)]).then(() => {
		if (result.wasCaptured) {
			return result.payload
		}

		throw new TimeoutError(ms)
	})
}

export class TimeoutError extends Error {
	name = 'TimeoutError'
	ms = 0

	constructor(ms: number) {
		super(`Promise timed out after ${ms}ms`)

		this.ms = ms

		// Maintains proper stack trace for where our error was thrown (only available on V8)
		if (Error.captureStackTrace) {
			Error.captureStackTrace(this, TimeoutError)
		}
	}
}
