import * as React from 'react'

import { FC } from 'src/utils/types'

type Modes = 'nowrap' | 'row' | 'col'
const modeMap: Record<Modes, React.CSSProperties> = {
	nowrap: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'nowrap',
	},
	row: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',
	},
	col: {
		display: 'flex',
		flexDirection: 'column',
		flexWrap: 'wrap',
	},
}

export const MeasureStack: FC<{
	rowChildren: React.ReactNode
	colChildren: React.ReactNode
}> = ({ rowChildren, colChildren }) => {
	const [mode, setMode] = React.useState<Modes>('nowrap')

	const rectsRef = React.useRef<Record<'full' | 'break', DOMRect | undefined>>({
		full: undefined,
		break: undefined,
	})
	const divRef = React.useRef<HTMLDivElement>(null)
	const measurement = useResize(divRef)

	React.useLayoutEffect(() => {
		if (!measurement) {
			return
		}

		const rects = rectsRef.current

		if (mode === 'nowrap') {
			rects.full = measurement
			setMode('row')
		} else if (mode === 'row') {
			if (rects.break) {
				if (measurement.width <= rects.break.width) {
					setMode('col')
				}
			} else if (rects.full) {
				if (rects.full.height < measurement.height) {
					rects.break = measurement
					setMode('col')
				}
			}
		} else if (mode === 'col') {
			if (rects.break) {
				if (rects.break.width < measurement.width) {
					setMode('row')
				}
			}
		}
	}, [mode, measurement])

	return (
		<div ref={divRef} style={modeMap[mode]}>
			{mode === 'nowrap' || mode === 'row' ? rowChildren : null}
			{mode === 'col' ? colChildren : null}
		</div>
	)
}

export const useIntervalTs = (ms: number) => {
	const [ts, setTs] = React.useState(0)

	React.useEffect(() => {
		const i = window.setInterval(() => {
			setTs(Date.now() as any)
		}, ms)
		return () => {
			window.clearInterval(i)
		}
	}, [ms])

	return ts
}

export const MediaStack: FC<{
	children: React.ReactNode
}> = ({ children }) => {
	const media = useMediaSet({
		sm: '(max-width: 26rem)',
		md: '(min-width: 26rem)',
		lg: '(min-width: 50rem)',
	})
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: media.has('lg') ? 'row' : 'column',
				flexWrap: 'wrap',
			}}
		>
			{children}
		</div>
	)
}

type Med = `(${string})`

export const useMediaSet = <NickMed extends Record<string, Med>>(
	nm: NickMed,
): Set<keyof NickMed> => {
	const nmRef = React.useRef(nm)
	nmRef.current = nm

	const [mediaSet, setMediaSet] = React.useState(new Set<keyof NickMed>())

	React.useEffect(() => {
		const vv = window.visualViewport
		if (!vv) {
			return
		}

		const cb = () => {
			const newSet = matchMediaSet(nmRef.current)
			if (!isSetEq(mediaSet, newSet)) {
				setMediaSet(newSet)
			}
		}
		cb()

		vv.addEventListener('resize', cb)
		return () => {
			vv.removeEventListener('resize', cb)
		}
	}, [mediaSet])

	return mediaSet
}

export const isSetEq = <V, S extends Set<V>>(a: S, b: S): boolean => {
	if (a.size !== b.size) {
		return false
	}

	for (const av of a) {
		if (!b.has(av)) {
			return false
		}
	}

	return true
}

export const matchMediaSet = <
	NickMed extends Record<string, Med>,
	Nick extends keyof NickMed
>(
	nm: NickMed,
): Set<Nick> => {
	const matchSet = new Set<Nick>()
	for (const n of Object.keys(nm) as Nick[]) {
		const m = nm[n]
		if (window.matchMedia(m).matches) {
			matchSet.add(n)
		}
	}
	return matchSet
}

export const useResize = <El extends HTMLElement>(
	elRef: React.RefObject<El>,
): DOMRect | undefined => {
	const [rect, setRect] = React.useState<DOMRect>()

	React.useEffect(() => {
		if (!elRef.current) {
			setRect(undefined)
			return
		}

		setRect(elRef.current.getBoundingClientRect())
		const resizeObserver = new ResizeObserver(entries => {
			setRect(entries[0]?.contentRect)
		})

		resizeObserver.observe(elRef.current)

		return () => {
			setRect(undefined)
			resizeObserver.disconnect()
		}
	}, [elRef.current])

	return rect
}
