import './Counter.css'

import * as React from 'react'

import { Json } from 'src/app/ui/Json'
import { req, Res } from 'src/utils/req'

type CountData = { count: number }

export const Counter = () => {
	const [res, setRes] = React.useState<Res<CountData>>({ state: 'loading' })

	const refresh = () => {
		setRes({ state: 'loading' })
		req<CountData>('/count').then(setRes)
	}

	const inc = () => {
		setRes({ state: 'loading' })
		req<CountData>('/count', 'POST').then(setRes)
	}

	const fail = () => {
		setRes({ state: 'loading' })
		req<CountData>('/dne').then(setRes)
	}

	React.useEffect(refresh, [])

	return (
		<div className="Counter">
			<h2>Counter API</h2>
			<p>Testing out a dead simple API:</p>

			<ul>
				<li>The server has a local "count" variable</li>
				<li>Each POST request increments that variable</li>
				<li>Each GET request has a random delay applied</li>
				<li>Each request includes timing information in the headers</li>
			</ul>

			<div>
				<button onClick={refresh}>Refresh</button>
				<button onClick={inc}>Increment</button>
				<button onClick={fail}>Fail</button>
			</div>

			<div>
				<h3>
					Request state: {res.state}{' '}
					{res.state !== 'loading' ? `(${res.status})` : null}
				</h3>
			</div>

			<div className="Counter-section">
				{res.state === 'success' ? (
					<>
						<h3>Data</h3>
						<Json data={res.data} />

						<h3>Response time (ms)</h3>
						<Json>
							Start: {res.startMs} {'\n'}
							End: {res.endMs} {'\n'}
							Total: {res.endMs - res.startMs}
						</Json>
					</>
				) : null}

				{res.state === 'failure' ? (
					<>
						<h3>Error message</h3>
						<Json>{res.message}</Json>
					</>
				) : null}
			</div>
		</div>
	)
}
