export type Res<D> = { state: 'loading' } | ResSuc<D> | ResFail

export type ResSuc<D> = {
	state: 'success'
	status: number
	data: D
	startMs: number
	endMs: number
	durationMs: number
}

export type ResFail = {
	state: 'failure'
	status: number
	message: string
}

export async function req<D>(
	apiRoute: string,
	method: 'GET' | 'POST' = 'GET',
): Promise<Res<D>> {
	const r = await fetch(`/api${apiRoute}`, { method })

	if (400 <= r.status && r.status < 500) {
		const message = await r.text()
		return {
			state: 'failure',
			status: r.status,
			message,
		}
	} else if (500 <= r.status && r.status < 600) {
		return {
			state: 'failure',
			status: r.status,
			message: 'Server error',
		}
	} else if (200 !== r.status) {
		return {
			state: 'failure',
			status: r.status,
			message: 'Unknown response',
		}
	}

	const data: D = await r.json()
	const startMs = parseInt(r.headers.get('X-Start-Ms') || 'NaN')
	const endMs = parseInt(r.headers.get('X-End-Ms') || 'NaN')
	return {
		state: 'success',
		status: r.status,
		data,
		startMs,
		endMs,
		durationMs: endMs - startMs,
	}
}
