import './WaveChart.css'

import * as React from 'react'

import { MicConnector } from './micConnector'
import { Visualizer } from './visualizer'

export const WaveChart = () => {
	return (
		<div className="WaveChart">
			<h2>Wave Chart</h2>

			<p>Rendering a microphone waveform with D3.</p>

			<Mic />
		</div>
	)
}

const Mic = () => {
	const [conErr, setConErr] = React.useState('')
	const svgRef = React.useRef<SVGSVGElement>(null)
	const visRef = React.useRef(new Visualizer())
	const micRef = React.useRef(new MicConnector())

	const connect = () => {
		setConErr('')
		micRef.current.connect().catch(err => {
			setConErr(err.message)
		})
	}

	React.useEffect(() => {
		visRef.current.connect(svgRef.current!)
		micRef.current.listen(dataArray => {
			visRef.current.draw(dataArray)
		})
	}, [])

	React.useEffect(() => {
		connect()

		const connector = micRef.current
		return () => {
			connector.disconnect()
		}
	}, [])

	return (
		<div>
			<svg ref={svgRef} />

			<p>
				<button
					onClick={() => {
						connect()
					}}
				>
					Reconnect
				</button>
			</p>

			{conErr && (
				<>
					<p>{conErr}</p>
					<p>Click "Reconnect" to try again.</p>
				</>
			)}
		</div>
	)
}
