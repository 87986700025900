import * as React from 'react'

// import { fmtJSON } from 'src/utils/fmt'
import { fmtJSON } from '@sangervasi/common/dist/utils/fmt'
import { FC } from 'src/utils/types'

export const Json: FC<{ data?: any }> = props => {
	let content = props.children
	if ('data' in props) {
		content = fmtJSON(props.data)
	}
	return (
		<pre>
			<code className="hljs js language-js">{content}</code>
		</pre>
	)
}
