type Handler = (args?: any) => void

export class Dispatcher<EventName extends string> {
	readonly names: EventName[]
	readonly nameToHandlers = new Map<EventName, Handler[]>()

	constructor(names: EventName[]) {
		this.names = names
	}

	addHandler(name: EventName, handler: Handler) {
		const handlers = this.nameToHandlers.get(name) || []
		this.nameToHandlers.set(name, handlers)

		handlers.push(handler)
	}

	removeHandler(name: EventName, handler: Handler) {
		const handlers = this.nameToHandlers.get(name) || []
		this.nameToHandlers.set(name, handlers)

		const i = handlers.indexOf(handler)
		if (0 <= i) {
			handlers.splice(i, 1)
		}
	}

	dispatch(name: EventName, args?: any) {
		const handlers = this.nameToHandlers.get(name) || []
		handlers.forEach(handler => {
			setTimeout(() => {
				handler(args)
			}, 0)
		})
	}

	dispatchSync(name: EventName, args?: any) {
		const handlers = this.nameToHandlers.get(name) || []
		handlers.forEach(handler => {
			handler(args)
		})
	}
}
