import * as React from 'react'
import { Link, Route, Switch } from 'react-router-dom'

import { Autosize } from 'src/app/playground/autosize/Autosize'
import { Connery } from 'src/app/playground/connery/Connery'
import { Counter } from 'src/app/playground/counter/Counter'
import { Declarative } from 'src/app/playground/Declarative'
import { ExampleDataChart } from 'src/app/playground/exampleDataChart/ExampleDataChart'
import { Paralaxative } from 'src/app/playground/paralaxative/Paralaxative'
import { WaveChart } from 'src/app/playground/waveChart/WaveChart'
import { PageList } from 'src/app/ui/PageList'

import { links } from './links'

export const Playground = () => {
	return (
		<div className="Playground">
			<Switch>
				<Route path="/playground/paralaxative">
					<Paralaxative />
				</Route>

				<Route path="/playground/autosize">
					<Autosize />
				</Route>

				<Route path="/playground/connery">
					<Connery />
				</Route>

				<Route path="/playground/counter">
					<Counter />
				</Route>

				<Route path="/playground/data">
					<ExampleDataChart />
				</Route>

				<Route path="/playground/wave">
					<WaveChart />
				</Route>

				<Route path="/playground/declarative">
					<Declarative />
				</Route>

				<Route>
					<h1>Playground</h1>

					<PageList links={links} />
				</Route>
			</Switch>
		</div>
	)
}
