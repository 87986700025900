import './Paralaxative.css'

import * as React from 'react'

import { ScrollControlArea, ScrollControlled } from './scrollControl'
import { SvgJank } from './SvgJank'

export const Paralaxative = () => {
	return (
		<div className="Paralaxative">
			<ScrollControlArea>
				<ScrollControlled stick style={{ zIndex: 1 }}>
					<h2>
						I am <em>really</em> not into these sites that put big paralax
						scrolling blocks that make it hard to see any actual information.
					</h2>
				</ScrollControlled>

				<ScrollControlled stick style={{ zIndex: 1 }}>
					<ScrollControlled
						keyframes={[100, 200, 300, 600, 900, 1500]}
						onKeyframe={evt => {
							console.log('onKeyframe', evt)
							const shifty: HTMLDivElement = evt.element.querySelector(
								'#shifty',
							)!
							// shifty.style.top = `${evt.scroll.y + 100}px`
							for (const child of shifty.children) {
								const childDiv = child as HTMLDivElement
								// ;(child as HTMLDivElement).style.top = `${kf + 100}px`
								childDiv.style.transition = 'transform 1s ease'
								const x = Math.random() * 90
								childDiv.style.transform = `translate3d(${x}vw, 0, 0)`
							}
						}}
					>
						<div
							id="shifty"
							style={{
								position: 'absolute',
							}}
						>
							<div style={{ position: 'relative' }}>Switch</div>
							<div style={{ position: 'relative' }}>Slide</div>
							<div style={{ position: 'relative' }}>Dip</div>
							<div style={{ position: 'relative' }}>Dive</div>
						</div>
					</ScrollControlled>
				</ScrollControlled>

				<div
					className="Paralaxative-section"
					style={{ height: 500, zIndex: 3 }}
				>
					<ScrollControlled
						keyframes={[0, 200, 400, 600, 800, 1_000, 1_200, 1_400]}
						style={{ height: 500, zIndex: 3 }}
						onKeyframe={evt => {
							const childDiv = evt.element.querySelector(
								'div',
							) as HTMLDivElement
							console.log('childDiv', childDiv)
							childDiv.style.transition = 'transform 1s ease'
							childDiv.style.transform = `translate3d(65vw, ${
								evt.keyframe + 100
							}px, 0) rotate(${evt.keyframe / 1_000}turn)`
						}}
					>
						<div
							id="spinny"
							style={{
								height: 200,
								width: 100,
								position: 'absolute',
								background: 'var(--bi-grad-lin)',
							}}
						/>
					</ScrollControlled>

					<ScrollControlled
						range={[300, 800]}
						style={{
							zIndex: 2,
							height: 100,
							transition: 'opacity ease-in 0.5s',
						}}
					>
						<div
							style={{
								height: 200,
								// width: 50,
								position: 'absolute',
								top: 600,
								left: 100,
							}}
						>
							<h2>Engage, extend, embiggen</h2>
						</div>
					</ScrollControlled>

					<ScrollControlled drift={200} style={{ height: 100 }}>
						<div
							style={{
								height: 200,
								position: 'absolute',
								left: 100,
							}}
						>
							<h2>Reconnecting transitional customer phase methods</h2>
						</div>
					</ScrollControlled>
				</div>

				<ScrollControlled drift={500}>
					<div className="Paralaxative-section" style={{}}>
						<SvgJank height={500} />
					</div>
				</ScrollControlled>

				<div className="Paralaxative-section">
					<SvgJank />
				</div>

				<SvgJank />
			</ScrollControlArea>
		</div>
	)
}
