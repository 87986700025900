import * as React from 'react'
import { Route, Switch } from 'react-router-dom'

import { Projects } from 'src/app/projects/Projects'
import { Thoughts } from 'src/app/thoughts/Thoughts'

export const Dev = () => {
	return (
		<div className="Dev">
			<Thoughts />
			<Projects />
		</div>
	)
}
