import './App.css'

import * as React from 'react'
import { Helmet } from 'react-helmet'
import { BrowserRouter, Link, Redirect, Route, Switch } from 'react-router-dom'
import { CompatRouter } from 'react-router-dom-v5-compat'

import { Dev } from 'src/app/dev/Dev'
import { Dictle } from 'src/app/dictle/Dictle'
import { MdPage } from 'src/app/mdPage/MdPage'
import { Playground } from 'src/app/playground/Playground'
import { Projects } from 'src/app/projects/Projects'
import { Thoughts } from 'src/app/thoughts/Thoughts'

import { Nav } from './Nav'
import { Recent } from './Recent'

export const App = () => {
	return (
		<BrowserRouter basename="/app">
			<CompatRouter>
				<div className="App">
					<Helmet>
						<title>Sangervasi.net</title>
						<meta
							name="description"
							content="Sebastian Sangervasi: A person."
						/>
						<link rel="canonical" href="https://sangervasi.net" />
					</Helmet>

					<div className="App-header">
						<header className="Header">
							<h1 className="Header-title">
								<Link to="/">Sangervasi</Link>
							</h1>

							<Nav />
						</header>
					</div>

					<section className="App-content">
						<Switch>
							<Route path="/" exact>
								<Recent />
							</Route>

							<Route path="/me" exact>
								<MdPage path="harmless" />
							</Route>

							<Route path="/dictle">
								<Dictle />
							</Route>

							<Route path="/dev">
								<Dev />
							</Route>

							<Route path="/thoughts">
								<Thoughts />
							</Route>

							<Route path="/projects">
								<Projects />
							</Route>

							<Route path="/playground">
								<Playground />
							</Route>

							<Route>
								<Redirect to="/" />
							</Route>
						</Switch>
					</section>

					<footer className="App-footer">
						<p>
							<a href="https://harmlesswriting.com">harmlesswriting.com</a>
						</p>
					</footer>
				</div>
			</CompatRouter>
		</BrowserRouter>
	)
}
