import './Dictle.css'

import Lodash from 'lodash'
import { DateTime, Duration } from 'luxon'
import * as React from 'react'
import { Helmet } from 'react-helmet'

import { FC } from 'src/utils/types'

import * as lib from './lib'
import { CLOSE, MATCH, MISS } from './lib'
import { Pop } from './Pop'

const GameContext = React.createContext(new lib.Game({ words: 'some' }))

const _someGame = () =>
	new lib.Game({
		words: 'some',
		guesses: [
			'anana',
			'aquas',
			'cacao',
			'cadre',
			'cases',
			'close',
			'count',
			'false',
			'guess',
			'looks',
			'maple',
			'match',
			'penis',
			'plump',
			'pumas',
			'scout',
			'slump',
			'small',
			'words',
			// "works"
		],
	})

const _allGame = () => {
	const game = new lib.Game({
		words: 'all',
		guesses: lib.WORDS_SAMPLE,
	})
	// ;(game as any).guesses = game.words.slice(0, 200)
	return game
}

export const Dictle = () => {
	const gameRef = React.useRef<lib.Game>(null!)
	if (!gameRef.current) {
		if (process.env.NODE_ENV === ('development XX' as any)) {
			// gameRef.current = _someGame()
			gameRef.current = _allGame()
		} else {
			gameRef.current = lib.Game.fromJson(
				window.localStorage.getItem('dictle') || '',
			)
		}
	}

	React.useEffect(() => {
		const callback = () => {
			window.localStorage.setItem('dictle', gameRef.current.toJson())
		}
		gameRef.current.dispatcher.addHandler('guesses', callback)
		return () => {
			gameRef.current.dispatcher.removeHandler('guesses', callback)
		}
	}, [])

	return (
		<div className="Dictle">
			<Helmet>
				<title>Dictle: Type all the words.</title>
				<meta
					name="description"
					// eslint-disable-next-line max-len
					content="Dictle is the logical conclusion of Wordle. To win you must enter every from Wordle dictionary exactly once. There are over twelve thousand words."
				/>
				<link rel="canonical" href="https://sangervasi.net/app/dictle" />
			</Helmet>

			<GameContext.Provider value={gameRef.current}>
				<div className="Dictle-header">
					<Header />
				</div>
				<div className="Dictle-panels">
					<div className="Dictle-panelLeft">
						<Guesses />
					</div>
					<div className="Dictle-panelRight">
						<Dict />
					</div>
				</div>
				<div className="Dictle-keyboard">
					<Keyboard />
				</div>
			</GameContext.Provider>
		</div>
	)
}

const Header = () => {
	return (
		<div className="DictleHeader">
			<div className="DictleHeader-title">
				<h1>Dictle</h1>
				<h2>For people who like typing words.</h2>
			</div>
			<div className="DictleHeader-links">
				<HowToPlay />
				<Stats />
			</div>
		</div>
	)
}

const HowToPlay = () => (
	<Pop
		button={<PopButton>How to Play</PopButton>}
		contentClass="DictleHeader-pop"
	>
		<div className="HowToPlay">
			<p>
				To complete this game you'll need to enter every word in the Wordle
				dictionary. There are about twelve thousand words.
			</p>
			<p>
				On the left are the words you've entered so far. The letters are colored
				to hint at a word you have <em>not</em> guessed yet. For example, if you
				have guessed "FLING" and "BLANK" you might be able to figure out you can
				guess "BLINK" next.
			</p>
			<p>
				On the right is a summary of how <em>every guess</em> applies to{' '}
				<em>every word</em> remaining. Words are grouped by how many matching,
				out-of-order, and missing letters they have, in no particular order.
			</p>
			<p>
				You can select a row on the right to work on words with different
				amounts of matches: easy greens, harder yellows, and so on.
			</p>
		</div>
	</Pop>
)

const Stats = () => {
	const game = React.useContext(GameContext)
	const [_, rerender] = React.useReducer(prev => prev + 1, 0)

	React.useEffect(() => {
		game.dispatcher.addHandler('guesses', rerender)
		rerender()

		return () => {
			game.dispatcher.removeHandler('guesses', rerender)
		}
	}, [])

	const endDate = game.opts.finishedAt
		? DateTime.fromMillis(game.opts.finishedAt)
		: DateTime.now()
	const startDate = DateTime.fromMillis(game.opts.startedAt)
	const duration = endDate
		.diff(startDate)
		.shiftTo('days', 'hours', 'minutes')
		.normalize()
	const guessed = game.guesses.length
	const words = game.words.length
	const finishDuration = Duration.fromMillis(
		duration.toMillis() * (words / Math.max(1, guessed)),
	)
	const finishDate = startDate.plus(finishDuration)

	const startStr = startDate.toLocaleString(DateTime.DATETIME_SHORT)
	const durationStr = duration.toHuman()
	const finishStr = finishDate.toLocaleString(DateTime.DATETIME_SHORT)

	return (
		<Pop
			button={<PopButton>Your Stats</PopButton>}
			contentClass="DictleHeader-pop"
		>
			{game.isFinished ? (
				<div className="Stats">
					<p>You started playing at {startStr}</p>
					<p>It's been {durationStr}</p>
					<p>You guessed all {guessed.toLocaleString()} words.</p>
					<p>You finished guessing at {finishStr}</p>
					<p>But why?</p>
				</div>
			) : (
				<div className="Stats">
					<p>You have been playing since {startStr}</p>
					<p>It's been {durationStr}</p>
					<p>
						You have guessed {guessed.toLocaleString()} out of{' '}
						{words.toLocaleString()} words.
					</p>
					<p>At this rate you'll finish around {finishStr}</p>
				</div>
			)}
		</Pop>
	)
}

const PopButton: FC = ({ children }) => {
	const { toggle } = React.useContext(Pop.Context)

	return (
		<button className="PopButton" onClick={() => toggle()}>
			{children}
		</button>
	)
}

const Guesses = () => {
	const game = React.useContext(GameContext)
	const [_, rerender] = React.useReducer(prev => prev + 1, 0)
	const [focusGuess, setFocusGuess] = React.useState('')
	const listRef = React.useRef<HTMLDivElement>(null!)

	const handleFocusGuess = (guess: string) => {
		setFocusGuess(guess)
	}

	React.useEffect(() => {
		game.dispatcher.addHandler('guesses', rerender)
		rerender()

		return () => {
			game.dispatcher.removeHandler('guesses', rerender)
		}
	}, [])

	React.useEffect(() => {
		if (!focusGuess) {
			return
		}
		const guessEl = listRef.current.querySelector(`#Guess-${focusGuess}`)
		if (!guessEl) {
			return
		}
		guessEl.scrollIntoView(false)
	}, [focusGuess])

	return (
		<div className="Guesses">
			<div className="Guesses-list" ref={listRef}>
				{game.guesses.map(guess => (
					<Guess key={guess} guess={guess} />
				))}
			</div>
			<div className="Guesses-input">
				<GuessInput onFocusGuess={handleFocusGuess} />
			</div>
		</div>
	)
}

const COMPARISON_TO_CLASS = {
	[MISS]: 'is-miss',
	[CLOSE]: 'is-close',
	[MATCH]: 'is-match',
} as const

const Guess: FC<{ guess: string }> = ({ guess }) => {
	const game = React.useContext(GameContext)
	const [_, rerender] = React.useReducer(prev => prev + 1, 0)
	const compResult = game.compareToActive(guess)
	const letters = guess.split('')

	React.useEffect(() => {
		game.dispatcher.addHandler('activeScore', rerender)
		rerender()

		return () => {
			game.dispatcher.removeHandler('activeScore', rerender)
		}
	}, [])

	return (
		<div className="Guess-container" id={`Guess-${guess}`}>
			{letters.map((letter, i) => {
				const comp = compResult[i]
				return (
					<div
						key={`${letter}${i}`}
						className={`Guess-letter ${COMPARISON_TO_CLASS[comp] || ''}`}
					>
						{letter}
					</div>
				)
			})}
		</div>
	)
}

const GuessInput: FC<{
	onFocusGuess: (guess: string) => void
}> = ({ onFocusGuess }) => {
	const game = React.useContext(GameContext)
	const [draftGuess, setDraftGuess] = React.useState('')
	const [inputState, setInputState] = React.useState<'ok' | 'invalid'>('ok')

	const handleInvalidGuess = () => {
		setInputState('invalid')
		setTimeout(() => {
			setInputState('ok')
		}, 500)
	}

	const handleKeyRef = React.useRef((e: KeyboardEvent) => {})
	handleKeyRef.current = (e: KeyboardEvent) => {
		if (e.ctrlKey || e.altKey || e.metaKey) {
			return
		}

		setInputState('ok')

		if (e.key === 'Backspace' || e.key === 'Delete') {
			setDraftGuess(draftGuess.slice(0, -1))
			return
		}

		if (e.key === 'Enter' || e.key === 'Return') {
			if (game.isGuessed(draftGuess)) {
				handleInvalidGuess()
				onFocusGuess(draftGuess)
				return
			}

			if (!game.isInList(draftGuess)) {
				handleInvalidGuess()
				return
			}

			game.guess(draftGuess)
			onFocusGuess(draftGuess)
			setDraftGuess('')
			return
		}

		const key = e.key.toLowerCase()
		if (!key.match(/^[a-z]$/)) {
			return
		}

		setDraftGuess((draftGuess + key).slice(0, game.opts.length))
	}

	React.useEffect(() => {
		const callback = (e: KeyboardEvent) => {
			handleKeyRef.current(e)
		}

		document.addEventListener('keydown', callback)
		return () => {
			document.removeEventListener('keydown', callback)
		}
	}, [])

	return (
		<div className={`Guess-container is-${inputState}`}>
			{Lodash.range(5).map(i => {
				const letter = draftGuess[i]

				return (
					<div key={`${letter}${i}`} className="Guess-letter is-blank">
						{letter}
					</div>
				)
			})}
		</div>
	)
}

const Dict = () => {
	const game = React.useContext(GameContext)
	const [_, rerender] = React.useReducer(prev => prev + 1, 0)

	React.useEffect(() => {
		game.dispatcher.addHandler('scoreInfo', rerender)
		game.dispatcher.addHandler('activeScore', rerender)
		rerender()

		return () => {
			game.dispatcher.removeHandler('scoreInfo', rerender)
			game.dispatcher.removeHandler('activeScore', rerender)
		}
	}, [])

	return (
		<div className="Dict">
			<div className="Dict-completed">
				{game.completedStr} = {game.scoreInfo.completed} (Completed)
			</div>
			{game.scoreInfo.top.map(({ count, str }) => {
				const checked = str === game.activeScoreStr
				return (
					<div
						key={str}
						className={`Dict-input ${checked ? 'is-checked' : ''}`}
					>
						<label>
							<input
								type="radio"
								name="Dict-score"
								onChange={() => game.setActive(str)}
								value={str}
								checked={checked}
							></input>
							{str} = {count}{' '}
						</label>
					</div>
				)
			})}
			{game.isFinished ? <Congratulations /> : null}
			{0 < game.scoreInfo.more ? (
				<div className="Dict-more">And {game.scoreInfo.more} more…</div>
			) : null}
			{game.isScoring ? <Loading /> : null}
			{/* <Loading /> */}
		</div>
	)
}

const LOADING_CHARS = ['🟩', '🟩', '🟨', '🟨', '⬛']
const Loading = () => {
	return (
		<div className="DictleLoading">
			{LOADING_CHARS.map((char, i) => (
				<div key={i} style={{ '--i': i } as any}>
					{char}
				</div>
			))}
		</div>
	)
}

const KEY_ROWS = [
	'qwertyuiop'.split(''),
	'asdfghjkl'.split(''),
	['Enter', ...'zxcvbnm'.split(''), 'Delete'],
]

const Keyboard = () => {
	const game = React.useContext(GameContext)
	const [_, rerender] = React.useReducer(prev => prev + 1, 0)

	React.useEffect(() => {
		game.dispatcher.addHandler('letters', rerender)
		rerender()

		return () => {
			game.dispatcher.removeHandler('letters', rerender)
		}
	}, [])

	return (
		<div className="Keyboard">
			{KEY_ROWS.map((row, i) => (
				<div className="Keyboard-row" key={i}>
					{row.map(key => {
						if (1 < key.length) {
							return (
								<button
									className="Keyboard-key"
									key={key}
									onClick={e => {
										document.dispatchEvent(
											new KeyboardEvent('keydown', { key }),
										)
									}}
								>
									{key}
								</button>
							)
						}

						const comp = game.compareLetter(key)
						return (
							<button
								className={`Keyboard-letter ${
									comp ? COMPARISON_TO_CLASS[comp] : 'is-blank'
								}`}
								key={key}
								onClick={e => {
									document.dispatchEvent(new KeyboardEvent('keydown', { key }))
								}}
							>
								{key}
							</button>
						)
					})}
				</div>
			))}
		</div>
	)
}

const Congratulations = () => (
	<video
		src="https://giant.gfycat.com/KindlyBabyishIndianspinyloach.mp4"
		width="100%"
		loop
		autoPlay
		muted
	/>
)
