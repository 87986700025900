import Lodash from 'lodash'

import { later } from '@sangervasi/common/dist/utils/promises'
import SOME_WORDS from 'src/data/someWords.json'
import ALL_WORDS from 'src/data/wordleAllWords.json'
import POSSIBLE_WORDS from 'src/data/wordlePossibleWords.json'
import { Dispatcher } from 'src/utils/dispatcher'

export const WORDS_SAMPLE = ALL_WORDS.slice(0, 1000)

export const count = () => {
	return ALL_WORDS.length
}

export interface GameOpts {
	words: 'all' | 'possible' | 'some'
	length: number
	top: number
	ascii: boolean
	guesses: string[]
	startedAt: number
	finishedAt?: number
}

export interface ScoreInfo {
	completed: number
	top: Array<{
		str: string
		count: number
	}>
	more: number
}

export class Game {
	readonly opts: GameOpts = {
		words: 'all',
		length: 5,
		top: 10,
		ascii: false,
		guesses: [],
		startedAt: Date.now(),
	}
	readonly words: string[] = []
	readonly guesses: string[] = []
	readonly dispatcher = new Dispatcher([
		'guesses',
		'scoreInfo',
		'activeScore',
		'letters',
	])

	private _guessesSet = new Set<string>()
	private _scoringFor: string[] = []
	private _letteringFor: string[] = []
	private _scores: WordScore[] = []
	private _scoreStrToWords: Partial<Record<string, string[]>> = {}
	private _scoreInfo?: ScoreInfo
	private _activeScoreStr?: string
	private _letterToComparison: Partial<Record<string, Comparison>> = {}
	private _wordToCompareResult: Partial<Record<string, CompareResult>> = {}

	toJson() {
		return JSON.stringify({
			...this.opts,
			guesses: this.guesses,
		})
	}

	static fromJson(jsonStr: string) {
		try {
			const opts = JSON.parse(jsonStr)
			if (typeof opts === 'object' && 'words' in opts) {
				return new Game(opts as GameOpts)
			}
		} catch {
			console.warn('Invalid game saved.')
		}

		return new Game({})
	}

	constructor(opts: Partial<GameOpts>) {
		this.opts = { ...this.opts, ...opts }

		if (this.opts.words === 'all') {
			this.words = ALL_WORDS as string[]
		} else if (this.opts.words === 'possible') {
			this.words = POSSIBLE_WORDS as string[]
		} else if (this.opts.words === 'some') {
			this.words = SOME_WORDS as string[]
		}

		if (opts.guesses?.length) {
			// Intentionally using same array to save space
			this.guesses = opts.guesses
			this._guessesSet = new Set(this.guesses)
			this.rescore(this.guesses)
		}
	}

	get scores(): WordScore[] {
		return this._scores
	}

	get scoreInfo(): ScoreInfo {
		return (
			this._scoreInfo || {
				completed: 0,
				top: [],
				more: 0,
			}
		)
	}

	get completedStr(): string {
		return stringifyScore(
			{
				match: this.opts.length,
				miss: 0,
				close: 0,
			},
			this.opts.ascii,
		)
	}

	get activeScoreStr(): string {
		return this._activeScoreStr || this.completedStr
	}

	get isFinished(): boolean {
		return Boolean(this.opts.finishedAt)
	}

	get isScoring(): boolean {
		return this._scoringFor.length > 0
	}

	get isLettering(): boolean {
		return this._letteringFor.length > 0
	}

	setActive(scoreStr: string) {
		const prev = this._activeScoreStr
		this._activeScoreStr = scoreStr
		if (prev !== this._activeScoreStr) {
			this.dispatcher.dispatch('activeScore')
			this.reletters()
		}
	}

	compareToActive(word: string): CompareResult {
		const activeWords = this._scoreStrToWords[this.activeScoreStr] || []
		const active = activeWords[0]
		if (!active) {
			return emptyResult(word)
		}

		return compare(active, word)
	}

	compareLetter(letter: string): Comparison | undefined {
		return this._letterToComparison[letter]
	}

	async reletters() {
		this._letteringFor.push(Lodash.last(this.guesses) || '')

		const letterToComparison: typeof this._letterToComparison = {}

		await later(() => {
			for (const guess of this.guesses) {
				const result = this.compareToActive(guess)
				result.forEach((comp, i) => {
					const letter = guess[i]
					const prev = letterToComparison[letter] || MISS
					letterToComparison[letter] =
						comp === MATCH
							? MATCH
							: comp === CLOSE && prev === MISS
							? CLOSE
							: prev
				})
			}
		})

		this._letterToComparison = letterToComparison
		this._letteringFor.shift()
		this.dispatcher.dispatch('letters')
	}

	scoreCount(score: WordScore): number {
		const scoreStr = stringifyScore(score, this.opts.ascii)
		return (this._scoreStrToWords[scoreStr] || []).length
	}

	isInList(word: string): boolean {
		if (word.length !== this.opts.length) {
			return false
		}

		const wi = Lodash.sortedIndexOf(this.words, word)
		if (wi === -1) {
			return false
		}
		return true
	}

	isGuessed(word: string): boolean {
		return this._guessesSet.has(word)
	}

	guess(word: string): boolean {
		if (this.isFinished) {
			return false
		}
		if (this.isGuessed(word)) {
			return false
		}
		if (!this.isInList(word)) {
			return false
		}

		this.guesses.push(word)
		this._guessesSet.add(word)

		if (this.guesses.length === this.words.length) {
			this.opts.finishedAt = Date.now()
		}

		this.dispatcher.dispatch('guesses')

		later(() => {
			this.rescore([word])
		})

		return true
	}

	/**
	 * Iterate over the words in sync chunks of a fixed size. Each chunk
	 * is put on the the event loop later to avoid blocking.
	 */
	async *asyncWords(size = 500) {
		let chunk = 0
		for (let i = 0; i < this.words.length; i += 1) {
			const word = this.words[i]
			if (chunk % size === 0) {
				await later(() => {})
			}

			yield word
			chunk += 1
		}
	}

	/**
	 * Generate the word scores from scratch for every input guess.
	 */
	async rescore(guesses: string[]) {
		this._scoringFor.push(Lodash.last(guesses) || '')
		this.dispatcher.dispatch('scoreInfo')

		await this.updateCompareResults(guesses)

		// Using local variables to ensure stacking async functions won't conflict.
		const scores: typeof this._scores = []
		const scoreStrToWords: typeof this._scoreStrToWords = {}

		for await (const word of this.asyncWords()) {
			if (this.isGuessed(word)) {
				continue
			}

			const cr = this._wordToCompareResult[word] || emptyResult(word)
			const score = scoreCompareResult(cr)
			const scoreStr = stringifyScore(score, this.opts.ascii)

			insertSortedUnique(scores, score, cmpScore)
			scoreStrToWords[scoreStr] = scoreStrToWords[scoreStr] || []
			scoreStrToWords[scoreStr]?.push(word)
		}

		this._scores = scores
		this._scoreStrToWords = scoreStrToWords

		this.reletters()

		let scoreInfo: typeof this._scoreInfo = null!
		await later(() => {
			scoreInfo = {
				completed: this.guesses.length,
				top: scores.slice(0, this.opts.top + 1).map(score => {
					const str = stringifyScore(score, this.opts.ascii)
					return {
						str,
						count: scoreStrToWords[str]?.length || 0,
					}
				}),
				more: Math.max(0, scores.length - this.opts.top),
			}
		})

		this._scoreInfo = scoreInfo
		this._scoringFor.shift()
		this.dispatcher.dispatch('activeScore')
		this.dispatcher.dispatch('scoreInfo')
	}

	/**
	 * Update the comparisons for every word based on the input guesses. The input
	 * array will be `this.guesses` when updating from scratch. Otherwise, just one
	 * guess is combined with every word.
	 */
	async updateCompareResults(guesses: string[]) {
		for (const guess of guesses) {
			for await (const word of this.asyncWords()) {
				const prev = this._wordToCompareResult[word] || emptyResult(word)
				const updated = combinedCompare(word, prev, guess)
				this._wordToCompareResult[word] = updated
			}
		}
	}
}

export const MISS = 'x'
export const CLOSE = '~'
export const MATCH = '@'
export const COMPARISONS = [MISS, CLOSE, MATCH] as const
export type Comparison = typeof COMPARISONS[number]

export type CompareResult = Comparison[]
export const compare = (actual: string, guess: string): CompareResult => {
	const actualChars = actual.split('')
	const guessChars = guess.split('')
	const availableChars = actual.split('')
	const result: CompareResult = emptyResult(guess)

	Lodash.zip(actualChars, guessChars).forEach(([actualChar, guessChar], i) => {
		if (actualChar === guessChar) {
			result[i] = MATCH
			availableChars[i] = ''
		}
	})

	guessChars.forEach((guessChar, i) => {
		// The guess was a match, so no searching for close is necessary.
		if (result[i] === MATCH) {
			return
		}

		const closeIndex = availableChars.indexOf(guessChar)
		if (0 <= closeIndex) {
			result[i] = CLOSE
			availableChars[closeIndex] = ''
		}
	})

	return result
}

const emptyResult = (word: string): CompareResult =>
	word.split('').map(() => MISS)

const emptyScore = (): WordScore => ({
	miss: 0,
	close: 0,
	match: 0,
})

export type CmpOrd = -1 | 0 | 1

const cmpArray = <I extends number | string | boolean, A extends I[]>(
	left: A,
	right: A,
) => {
	for (let i = 0; i < left.length; i += 1) {
		const lv = left[i]
		const rv = right[i]
		if (lv < rv) {
			return -1
		} else if (rv < lv) {
			return 1
		}
	}

	return 0
}

export const cmpScore = (left: WordScore, right: WordScore): CmpOrd =>
	cmpArray(
		[-left.match, -left.close, left.miss],
		[-right.match, -right.close, right.miss],
	)

export interface WordScore {
	miss: number
	close: number
	match: number
}

export const combinedCompare = (
	word: string,
	prev: CompareResult,
	guess: string,
): CompareResult => {
	const combined = [...prev]
	const result = compare(word, guess)
	const availableChars = word.split('')
	result.forEach((comp, i) => {
		if (comp === MATCH) {
			combined[i] = MATCH
			availableChars[i] = ''
		} else if (comp === CLOSE) {
			const char = guess[i]
			const charIndex = availableChars.indexOf(char)
			availableChars[charIndex] = ''

			if (combined[charIndex] === MISS) {
				combined[charIndex] = CLOSE
			}
		}
	})
	return combined
}

export const scoreCompareResult = (cr: CompareResult): WordScore => {
	const score = emptyScore()
	const counts = Lodash.countBy(
		cr,
		comp =>
			({
				[MISS]: 'miss',
				[MATCH]: 'match',
				[CLOSE]: 'close',
			}[comp]),
	)

	Object.assign(score, counts)
	return score
}

export const wordScore = (word: string, guesses: string[]): WordScore => {
	let combined = emptyResult(word)

	guesses.forEach(guess => {
		combined = combinedCompare(word, combined, guess)
	})

	return scoreCompareResult(combined)
}

export const stringifyScore = (score: WordScore, ascii = false) =>
	[
		Lodash.repeat(ascii ? MATCH : '🟩', score.match),
		Lodash.repeat(ascii ? CLOSE : '🟨', score.close),
		Lodash.repeat(ascii ? MISS : '⬛', score.miss),
	].join('')

export const sortedInsertionIndex = <I>(
	arr: I[],
	item: I,
	cmpFn: (left: I, right: I) => CmpOrd,
): number => {
	let left = 0
	let right = arr.length - 1

	while (left <= right) {
		const mid = Math.floor((left + right) / 2)
		const cmp = cmpFn(arr[mid], item)

		if (cmp < 0) {
			left = mid + 1
		} else if (0 < cmp) {
			right = mid - 1
		} else {
			return mid
		}
	}

	return left
}

export const insertSortedUnique = <I>(
	arr: I[],
	item: I,
	cmpFn: (left: I, right: I) => CmpOrd,
): number => {
	const insertionIndex = sortedInsertionIndex(arr, item, cmpFn)
	if (arr.length <= insertionIndex) {
		arr.push(item)
		return insertionIndex
	}

	const insertionItem = arr[insertionIndex]
	const cmp = cmpFn(insertionItem, item)

	if (cmp !== 0) {
		arr.splice(insertionIndex, 0, item)
	}
	return insertionIndex
}
