import { PageLink } from 'src/app/ui/PageList'

export const links: PageLink[] = [
	{
		date: '2022-02-22',
		link: '/thoughts/grid-maps',
		name: 'Use Maps, Not Nested Arrays',
		page: 'thoughts/grid-maps',
	},
	{
		date: '2021-06-21',
		link: '/thoughts/declarative',
		name: 'Declarative Components, Imperative Objects',
		page: 'thoughts/declarative',
	},
	{
		date: '2021-01-01',
		link: '/thoughts/stacks',
		name: 'Stacks',
		page: 'thoughts/stacks',
	},
]
