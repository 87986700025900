import * as React from 'react'

import { PageList } from 'src/app//ui/PageList'
import { MdPage } from 'src/app/mdPage/MdPage'

export const Recent = () => {
	const links = [
		{
			date: '2023-10-09',
			href:
				'https://open.substack.com/pub/harmless/p/trust-and-safety-tycoon-taught-me?utm_campaign=post&utm_medium=web',
			name: 'Trust and Safety tycoon taught me nothing',
		},
		{
			date: '2023-06-29',
			href:
				'https://open.substack.com/pub/harmless/p/trust-and-safety-tycoon-taught-me?utm_campaign=post&utm_medium=web',
			name: 'Are you prepared to leave Substack?',
		},
		{
			date: '2022-11-16',
			href: 'https://harmlesswriting.com/2023/03/18/mileage/',
			name: 'Mileage - A speculative vehicle disaster',
		},
		{
			date: '2021-06-29',
			link: '/projects/tiasbitb',
			name: 'There is a Spiky Ball in the Bathtub',
		},
		{
			date: 'Eternal',
			href: '/a-website.html',
			name: 'An educational guide to the web',
		},
	]

	return (
		<>
			<h1>Fun little things:</h1>
			<PageList links={links} />

			<MdPage path="unbound" />
		</>
	)
}
